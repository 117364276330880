// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-albums-ceramics-and-mixed-media-early-works-js": () => import("./../src/pages/albums/ceramics-and-mixed-media/early-works.js" /* webpackChunkName: "component---src-pages-albums-ceramics-and-mixed-media-early-works-js" */),
  "component---src-pages-albums-ceramics-and-mixed-media-index-js": () => import("./../src/pages/albums/ceramics-and-mixed-media/index.js" /* webpackChunkName: "component---src-pages-albums-ceramics-and-mixed-media-index-js" */),
  "component---src-pages-albums-ceramics-and-mixed-media-recent-works-js": () => import("./../src/pages/albums/ceramics-and-mixed-media/recent-works.js" /* webpackChunkName: "component---src-pages-albums-ceramics-and-mixed-media-recent-works-js" */),
  "component---src-pages-albums-index-js": () => import("./../src/pages/albums/index.js" /* webpackChunkName: "component---src-pages-albums-index-js" */),
  "component---src-pages-albums-installations-berlin-project-js": () => import("./../src/pages/albums/installations/berlin-project.js" /* webpackChunkName: "component---src-pages-albums-installations-berlin-project-js" */),
  "component---src-pages-albums-installations-curatorial-project-js": () => import("./../src/pages/albums/installations/curatorial-project.js" /* webpackChunkName: "component---src-pages-albums-installations-curatorial-project-js" */),
  "component---src-pages-albums-installations-early-projects-js": () => import("./../src/pages/albums/installations/early-projects.js" /* webpackChunkName: "component---src-pages-albums-installations-early-projects-js" */),
  "component---src-pages-albums-installations-environmental-justice-js": () => import("./../src/pages/albums/installations/environmental-justice.js" /* webpackChunkName: "component---src-pages-albums-installations-environmental-justice-js" */),
  "component---src-pages-albums-installations-index-js": () => import("./../src/pages/albums/installations/index.js" /* webpackChunkName: "component---src-pages-albums-installations-index-js" */),
  "component---src-pages-albums-paintings-early-works-js": () => import("./../src/pages/albums/paintings/early-works.js" /* webpackChunkName: "component---src-pages-albums-paintings-early-works-js" */),
  "component---src-pages-albums-paintings-index-js": () => import("./../src/pages/albums/paintings/index.js" /* webpackChunkName: "component---src-pages-albums-paintings-index-js" */),
  "component---src-pages-albums-paintings-recent-works-js": () => import("./../src/pages/albums/paintings/recent-works.js" /* webpackChunkName: "component---src-pages-albums-paintings-recent-works-js" */),
  "component---src-pages-albums-paintings-works-on-paper-js": () => import("./../src/pages/albums/paintings/works-on-paper.js" /* webpackChunkName: "component---src-pages-albums-paintings-works-on-paper-js" */),
  "component---src-pages-bio-index-js": () => import("./../src/pages/bio/index.js" /* webpackChunkName: "component---src-pages-bio-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cv-index-js": () => import("./../src/pages/cv/index.js" /* webpackChunkName: "component---src-pages-cv-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-statement-index-js": () => import("./../src/pages/statement/index.js" /* webpackChunkName: "component---src-pages-statement-index-js" */),
  "component---src-pages-studio-index-js": () => import("./../src/pages/studio/index.js" /* webpackChunkName: "component---src-pages-studio-index-js" */),
  "component---src-pages-success-index-js": () => import("./../src/pages/success/index.js" /* webpackChunkName: "component---src-pages-success-index-js" */)
}

